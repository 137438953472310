<template>
	<w-flex scroll-y>
		<w-data-table
			:headers="headers"
			:items="services"
			:loading="loadings > 0"
			:pagination.sync="pagination"
			:rows-per-page-items="[5, 15, 25, 50]"
			:total-items="pagination.totalItems"
			@click.native.stop
		>
			<template v-slot:items="{ item }">
				<td :class="{ pointer: true, service__selected: selectedServiceId === item.id, 'text-xs-center': true }" @click="selectService(item)">
					<v-avatar
						:class="{
							'font-weight-bold': true,
							'white--text': $getContrastedTextColor(item.category.color) === 'white--text'
						}"
						:color="item.category.color"
						size="40px"
						>{{ item.category.abbreviation }}</v-avatar
					>
				</td>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
					v-text="item.unique_reference"
				/>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
					v-text="item.name"
				/>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
				>
					<w-flex
						:class="{
							'project-link': true,
							'text-underline primary--text': item.project.id === selectedProjectId,
							'font-weight-bold': item.project.id === selectedProjectId && !selectedServiceId
						}"
						@click.stop="selectProject(item.project)"
						v-text="item.project.name"
					/>
				</td>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
					v-text="$d(new Date(item.start), 'tiny')"
				/>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
					v-text="$d(new Date(item.end), 'tiny')"
				/>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
					v-text="$n(item.price, 'currency')"
				/>
				<td
					:class="{
						pointer: true,
						service__selected: selectedServiceId === item.id
					}"
					@click="selectService(item)"
				>
					{{
						item.time_allocation
							? `${(item.time_allocation - (item.time_allocation % 60)) / 60}h${(item.time_allocation % 60).toString().padStart(2, '0')}`
							: null
					}}
				</td>
			</template>
		</w-data-table>
	</w-flex>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

import ProjectsListMixin from '@/mixins/Offers/Projects/ProjectsList'

export default {
	name: 'ProjectsListTable',
	mixins: [ProjectsManagerModuleGuard, ProjectsListMixin],
	data: function () {
		return {
			cancelToken: null,
			headers: [
				{
					align: 'center',
					sortable: false,
					text: this.$tc('projects.categories', 1)
				},
				{
					align: 'center',
					text: this.$t('projects.reference'),
					value: 'unique_reference'
				},
				{
					align: 'center',
					text: this.$tc('projects.services', 1),
					value: 'name'
				},
				{
					align: 'center',
					text: this.$tc('projects.project', 1),
					value: 'project_name'
				},
				{
					align: 'center',
					text: this.$t('projects.start_date'),
					value: 'start'
				},
				{
					align: 'center',
					text: this.$t('projects.end_date'),
					value: 'end'
				},
				{
					align: 'center',
					text: this.$t('projects.price'),
					value: 'price'
				},
				{
					align: 'center',
					text: this.$t('projects.time_allocation'),
					value: 'time_allocation'
				}
			],
			loadings: 0,
			services: []
		}
	},
	methods: {
		filtersWatcher: function () {
			this.listServices()
		},
		listServices: function () {
			this.loadings++
			this.services = []

			const callAnswer = this.service.listServices(this.accountingFirmId, this.vendorId, this.filters, this.pagination, this.cancelToken)

			this.cancelToken = callAnswer.cancelToken

			return callAnswer.promise
				.then(({ paginationData, services }) => {
					this.$store.dispatch('customers/projects/setPagination', {
						page: paginationData.currentPage,
						totalItems: paginationData.total
					})
					this.services = services
				})
				.finally(() => {
					this.loadings--
				})
		},
		onProjectCreated: function () {},
		onProjectCompleted: function (project) {
			const servicesIds = project.services.map(service => service.id)

			let containsServices = false

			for (let i = 0; i < servicesIds.length; i++) {
				if (this.services.some(s => servicesIds.includes(s.id))) {
					containsServices = true
				}
			}

			if (containsServices) {
				this.listServices()
			}
		},
		onProjectDeleted: function () {
			this.listServices()
		},
		onProjectUpdated: function (project) {
			if (!project.services) {
				return
			}

			const servicesIds = project.services.map(service => service.id)

			this.services.forEach((service, serviceIndex) => {
				if (!servicesIds.includes(service.id)) {
					return
				}

				this.services[serviceIndex].update({
					project
				})
			})
		},
		onServiceCreated: function (service) {
			this.services.unshift(service)
			if (this.services.length > this.pagination.rowsPerPage) {
				this.services.pop()
			}
		},
		onServiceDeleted: function () {
			this.listServices()
		},
		onServiceUpdated: function (service) {
			const serviceIndex = this.services.findIndex(s => s.id === service.id)
			if (serviceIndex !== -1) {
				this.services[serviceIndex].update(service)
			}
		},
		paginationWatcher: function () {
			this.listServices()
		}
	}
}
</script>

<style scoped>
:deep(.project-link:hover) {
	color: var(--v-primary-base);
	text-decoration: underline;
	transition: 0.5s;
}

:deep(.service__selected) {
	background-color: var(--v-softGrey-darken1);
}

:deep(table.theme--dark .service__selected) {
	background-color: var(--v-softGrey-darken4);
}
</style>
