var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-flex',{attrs:{"scroll-y":""}},[_c('w-data-table',{attrs:{"headers":_vm.headers,"items":_vm.services,"loading":_vm.loadings > 0,"pagination":_vm.pagination,"rows-per-page-items":[5, 15, 25, 50],"total-items":_vm.pagination.totalItems},on:{"update:pagination":function($event){_vm.pagination=$event}},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"items",fn:function({ item }){return [_c('td',{class:{ pointer: true, service__selected: _vm.selectedServiceId === item.id, 'text-xs-center': true },on:{"click":function($event){return _vm.selectService(item)}}},[_c('v-avatar',{class:{
						'font-weight-bold': true,
						'white--text': _vm.$getContrastedTextColor(item.category.color) === 'white--text'
					},attrs:{"color":item.category.color,"size":"40px"}},[_vm._v(_vm._s(item.category.abbreviation))])],1),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},domProps:{"textContent":_vm._s(item.unique_reference)},on:{"click":function($event){return _vm.selectService(item)}}}),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){return _vm.selectService(item)}}}),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},on:{"click":function($event){return _vm.selectService(item)}}},[_c('w-flex',{class:{
						'project-link': true,
						'text-underline primary--text': item.project.id === _vm.selectedProjectId,
						'font-weight-bold': item.project.id === _vm.selectedProjectId && !_vm.selectedServiceId
					},domProps:{"textContent":_vm._s(item.project.name)},on:{"click":function($event){$event.stopPropagation();return _vm.selectProject(item.project)}}})],1),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},domProps:{"textContent":_vm._s(_vm.$d(new Date(item.start), 'tiny'))},on:{"click":function($event){return _vm.selectService(item)}}}),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},domProps:{"textContent":_vm._s(_vm.$d(new Date(item.end), 'tiny'))},on:{"click":function($event){return _vm.selectService(item)}}}),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},domProps:{"textContent":_vm._s(_vm.$n(item.price, 'currency'))},on:{"click":function($event){return _vm.selectService(item)}}}),_c('td',{class:{
					pointer: true,
					service__selected: _vm.selectedServiceId === item.id
				},on:{"click":function($event){return _vm.selectService(item)}}},[_vm._v(" "+_vm._s(item.time_allocation ? `${(item.time_allocation - (item.time_allocation % 60)) / 60}h${(item.time_allocation % 60).toString().padStart(2, '0')}` : null)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }