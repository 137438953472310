import Service from '@/services/Offers/ProjectsManagerService'
import { Bus, Events } from '@/events/Offers/ProjectsManagerEvents'

export default {
	name: 'ProjectsList',
	props: {
		selectedProjectId: {
			default: undefined,
			required: false,
			type: Number
		},
		selectedServiceId: {
			default: undefined,
			required: false,
			type: Number
		}
	},
	created: function () {
		Bus.initializeListeners(this.eventsActionsMapping, this)
	},
	destroyed: function () {
		Bus.releaseListeners(this)
	},
	data: function () {
		return {
			eventsActionsMapping: [
				{ action: this.onProjectCreated, event: Events.PROJECT_CREATED },
				{ action: this.onProjectCompleted, event: Events.PROJECT_COMPLETED },
				{ action: this.onProjectDeleted, event: Events.PROJECT_DELETED },
				{ action: this.onProjectUpdated, event: Events.PROJECT_UPDATED },
				{ action: this.onServiceCreated, event: Events.SERVICE_CREATED },
				{ action: this.onServiceDeleted, event: Events.SERVICE_DELETED },
				{ action: this.onServiceUpdated, event: Events.SERVICE_UPDATED }
			]
		}
	},
	computed: {
		filters: function () {
			return this.$store.state.customers.projects.filters
		},
		pagination: {
			get: function () {
				return this.$store.state.customers.projects.pagination
			},
			set: function (val) {
				return this.$store.dispatch('customers/projects/setPagination', val)
			}
		}
	},
	watch: {
		filters: {
			deep: true,
			handler: 'filtersWatcher',
			immediate: true
		},
		pagination: {
			deep: true,
			handler: 'paginationWatcher',
			immediate: true
		}
	},
	methods: {
		selectProject: function (project) {
			Service.selectProject(project.id)
		},
		selectService: function (service) {
			let projectId
			if (service.hasOwnProperty('project_id')) {
				projectId = service.project_id
			} else {
				projectId = service.project.id
			}
			Service.selectService(projectId, service.id)
		}
	}
}
